export default {
    home_page: "/",
    archive_classes: "/classes",
    content_page: "/content",
    trainers_page: "/trainers",
    instructors_page: "/instructors",
    scheduler_page: "/scheduler",
    faqs_page: "/faqs",
    contact_page: "/contact-us",
    try_us:"/try-us",
    terms_of_service:"/terms-of-service",
    privacy_policy_page:"/privacy-policy",
    join_us_page: "/join-us",
    thank_you: "/thank-you",
    corporate_enrollment: "/corporate-enrollment",
    restore_pass: "/restore-pass"
};