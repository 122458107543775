import { locationMOSOSort, locationPrismicSort } from "./helpersInterfaces";
import { categories } from "../templates/Scheduler_page/FilterNav/FilterNavInterfaces";

export const encode = (data: any): string => {
    return Object.keys(data)
        .map(
            key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
        )
        .join("&");
};

export const slugify = (value: string): string => {
    return value
        .toString()
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/\s+/g, "-")
        .replace(/&/g, "-and-")
        .replace(/[^\w\-]+/g, "")
        .replace(/\-\-+/g, "-")
        .replace(/^-+|-+$/, "");
};

export const isBrowser = (): boolean => typeof window !== "undefined";

//#region  dictionary
const weekDay = [
    { large: "Sunday", short: "Sun" },
    { large: "Monday", short: "Mon" },
    { large: "Tuesday", short: "Tue" },
    { large: "Wednesday", short: "Wed" },
    { large: "Thursday", short: "Thu" },
    { large: "Friday", short: "Fri" },
    { large: "Saturday", short: "Sat" },
];

const Month = [
    { large: "January", short: "Jan" },
    { large: "February", short: "Feb" },
    { large: "March", short: "Mar" },
    { large: "April", short: "Apr" },
    { large: "May", short: "May" },
    { large: "June", short: "Jun" },
    { large: "July", short: "Jul" },
    { large: "August", short: "Aug" },
    { large: "September", short: "Sep" },
    { large: "October", short: "Oct" },
    { large: "November", short: "Nov" },
    { large: "December", short: "Dec" },
];
//#endregion

// format date in month, day of week and number
export const dateHandler = (currentDate: Date, data?: any) => {
    let day = currentDate.getDate();
    let positionDay = currentDate.getDay();
    let month = currentDate.getMonth();
    let year = currentDate.getFullYear();
    let dayNumberTwoDigits = day < 10 ? `0${day}` : day;
    let monthNumberTwoDigits = (month + 1) < 10 ? `0${month + 1}` : month + 1

    return {
        fullNumberDate: `${year}-${monthNumberTwoDigits}-${dayNumberTwoDigits}`,
        numberDate: `${month + 1}/${day}`,
        dayNumber: day,
        monthName: Month[month],
        yearNumber: year,
        dayName: weekDay[positionDay],
        UTCdate: currentDate,
        classesData: data ? [data] : [],
    };
};

//split date format to display PM || AM || HS
export const handleSplit = (hour: string | null) => {
    if (!hour) return;

    let splitted = hour.split(":");
    let format;

    if (splitted[2].search("PM") !== -1) {
        format = splitted[2].slice(splitted[2].search("PM"));
    } else if (splitted[2].search("AM") !== -1) {
        format = splitted[2].slice(splitted[2].search("AM"));
    } else {
        format = "HS";
    }

    return `${splitted[0]}:${splitted[1]} ${format} `;
};

export const toDate = (argument: any) => {
    var argStr = Object.prototype.toString.call(argument); // Clone the date

    if (
        argument instanceof Date ||
        (typeof argument === "object" && argStr === "[object Date]")
    ) {
        // Prevent the date to lose the milliseconds when passed to new Date() in IE10
        return new Date(argument.getTime());
    } else if (typeof argument === "number" || argStr === "[object Number]") {
        return new Date(argument);
    } else {
        if (
            (typeof argument === "string" || argStr === "[object String]") &&
            typeof console !== "undefined"
        ) {
            // eslint-disable-next-line no-console
            console.warn(
                "Starting with v2.0.0-beta.1 date-fns doesn't accept strings as date arguments. Please use `parseISO` to parse strings. See: https://git.io/fjule"
            ); // eslint-disable-next-line no-console

            console.warn(new Error().stack);
        }

        return new Date(NaN);
    }
};

export const getYear = (dirtyDate: any) => {
    var date = toDate(dirtyDate);
    var year = date.getFullYear();
    return year;
};

export const getMonth = (dirtyDate: any) => {
    var date = toDate(dirtyDate);
    var month = date.getMonth();
    return month;
};

export function orderLocations(locationObject: Array<locationMOSOSort | locationPrismicSort>) {
    const order = { 50042: 0, 50043: 1, 29942: 2, 50022: 3, 29943:4, 50039: 5, default:1000 } as any;
    const sortedLocations = locationObject.sort(
        function (a,b) {
            return (order[a.businessUnitCode] ?? order.default) - (order[b.businessUnitCode] ?? order.default);
        }
    )
    return sortedLocations
  }

//sort filterNav in scheduler
export function sortByKey(key: string, array: any[]) {
    if (!array) return null as any;
    return array.sort((a: any, b: any) => {
        const x = a[key];
        const y = b[key];
        if (x > y) return 1;
        if (x < y) return -1;
        return 0;
    });
};

export function getSeoTitle(data: any, def: string) {
    if (data && data.seo_title && data.seo_title.length > 0)
        return data?.seo_title[0].text
    else if (data && data.page_title && data.page_title.length > 0)
        return data?.page_title[0].text
    return def;
};

export function getSeoDescription(data: any, def: string) {
    if (data && data.seo_description && data.seo_description.length > 0)
        return data?.seo_description[0].text
    else if (data && data.page_description && data.page_description.length > 0)
        return data?.page_description[0].text
    return def;
};

export function getSeoKeywords(data: any, def: string) {
    if (data && data.seo_keywords && data.seo_keywords.length > 0)
        return data?.seo_keywords[0].text
    return def;
};

export const isWindow = typeof window !== "undefined" ? true : false;

export function replaceBetween (start: number, end: number , text: string , reptext: string ) {
    return text.substring(0, start) + reptext + text.substring(end);
};

// Get UTM string values from searchParams

export function getUtmString(searchParams?: URLSearchParams) {
    if (searchParams){
        const utmKeys = ["utm_source", "utm_medium", "utm_campaign", "creative", "placement", "network"];
        let parsedParams:string[] = [];
        for (let value of utmKeys) {
            if (searchParams.has(value))
                parsedParams.push(`${value}=${searchParams.get(value)}`);
        }
        return parsedParams.length > 0 ? parsedParams.join("&") : null;
    }
    return null;
}